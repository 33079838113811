import Vue from "vue";
// import { createDecorator } from "vue-class-component";
// import lodash from "lodash";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";

import HeaderBlock from "@/components/header/header.block.vue";
import FooterBlock from "@/components/footer/footer.block.vue";
import GridBlock from "@/components/grid/grid.block.vue";
import HeroBlock from "@/components/hero/hero.block.vue";
import ModalBlock from "@/components/modal/modal.block.vue";

import CapitalizeFilter from "@/filters/capitalize.filter";
import DateFilter from "@/filters/date.filter";
import CurrencyFilter from "@/filters/currency.filter";
import EnumerateFilter from "@/filters/enumerate.filter";
import StripHtmlFilter from "@/filters/strip-html.filter";

Vue.config.productionTip = false;

// Register blocks.
Vue.component("header-block", HeaderBlock);
Vue.component("footer-block", FooterBlock);
Vue.component("grid-block", GridBlock);
Vue.component("hero-block", HeroBlock);
Vue.component("modal-block", ModalBlock);

// Register filters.
Vue.filter("capitalize", CapitalizeFilter);
Vue.filter("enumerate", EnumerateFilter);
Vue.filter("date", DateFilter);
Vue.filter("currency", CurrencyFilter);
Vue.filter("stripHtml", StripHtmlFilter);

// The Vue instance.
export default new Vue({
	router,
	store,
	render: h => h(App),
}).$mount("#app");

// Initialization of stores.
// store.dispatch("i18n/initModule");

// export const i18n = createDecorator((options, key) => {
//   // component options should be passed to the callback
//   // and update for the options object affect the component
//   options.computed[key].cache = false
// })
