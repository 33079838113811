import { Vue, Component, Prop, Provide } from "vue-property-decorator";

@Component({
	name: "footer-block"
})

export default class FooterBlockComponent extends Vue {
	private email: string = "jafal.jove@gmail.com";
	private currentYear: number = new Date().getFullYear();
}
