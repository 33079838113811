import { Vue, Component, Prop, Provide } from "vue-property-decorator";

@Component({
	name: "ModalBlock"
})

export default class ModalBlockComponent extends Vue {

	private isOpen: boolean = false;
	private bodyScrollStoppingClass: string = "no--scroll";

	private created() {
		this.openModal();
	}

	private closeModal() {
		if (window.history.length > 1) {
			this.$router.go(-1);
		} else {
			this.$router.push({"name": "HomeView"});
		}

		this.isOpen = false;
		document.querySelector("body")!.classList.remove(this.bodyScrollStoppingClass);
	}

	private openModal() {
		this.isOpen = true;
		document.querySelector("body")!.classList.add(this.bodyScrollStoppingClass);
	}
}
