
import { Route } from "vue-router";
import { Vue, Component, Watch, Provide, Model } from "vue-property-decorator";
import { SpawnSyncOptionsWithBufferEncoding } from 'child_process';

@Component({
	name: "HomePage"
})

export default class HomePage extends Vue {


	@Provide() private content!: any;
	@Provide() private keywords: string[] = [];
	@Provide() private tags: any = ["Africa", "Tribal", "Fusion", "World"];
	@Provide() private entryTypes: any = [
		{
			name: "Music",
			value: "music"
		},
		{
			name: "Project Photos",
			value: "photo"
		},
		{
			name: "Video",
			value: "video"
		},
	];

	@Provide() private sortOptions: any = [
		{
			name: "Newest",
			value: "new",
			key: "date"
		},
		{
			name: "Alphabetically",
			value: "alpha",
			key: "title"
		}
	];


	@Provide() private last: any = undefined;

	@Provide() private filterState: any = {
		keywords: "",
		type: undefined,
		sort: this.sortOptions[0].value
	};

	@Watch("$route") private onRouteChange(route: Route) {

		if (route.name === "HomeView") {
			this.filterState.type = null;
		}

		if (route.name === "SearchView") {
			if (route.query.type) {
				this.setStateFromUrl();
			} else {
				this.saveStateInUrl();
			}
		}
	}

	private created(): void {
		this.setStateFromUrl();
		this.getContent();
	}

	/**
	 * Get content.
	 */
	private getContent() {
		this.content = require("@/data/list.json").data;
	}

	/**
	 * Evaluate if type should be selected ou de-selected based on current state.
	 */
	private evalTypeSelection(value: string): void {
		if (this.filterState.type !== value) {
			this.filterState.type = value;
		} else {
			this.filterState.type = undefined;
		}
		this.saveStateInUrl();
	}

	/**
	 * Save filter state in URL.
	 */
	private saveStateInUrl(): void {
		let query = {};

		if (this.filterState.type) {
			query = {type: this.filterState.type};
		}

		if (this.filterState.sort !== this.sortOptions[0].value) {
			query = Object.assign(query, {sort: this.filterState.sort});
		}

		this.$router.replace({"name": "SearchView", "query": query});
	}

	/**
	 * Set filter state from URL.
	 */
	private setStateFromUrl(): void {
		if (this.$route.query.type) {
			this.filterState.type = this.$route.query.type;
		}

		if (this.$route.query.sort) {
			this.filterState.sort = this.$route.query.sort;
		}
	}

	/**
	 * Add keyword as a chip.
	 */
	private addKeyword() {
		// if (this.filterState.keywords) {
			// 	this.keywords.push(this.filterState.keywords);
		// 	this.filterState.keywords = "";
		// }
	}

	/**
	 * Sort results by age or alphabetically.
	 */
	private sort(content: any) {
		const key = this.sortOptions.find((x: any) => x.value === this.filterState.sort).key;

		return content.sort((a: any, b: any) => {
			return a[key].localeCompare(b[key]);
		});
	}

	/**
	 * Filter results by title and type.
	 */
	private filter(content: any) {
		return content.filter((item: any) => {
			return item.title.toLowerCase().includes(this.filterState.keywords.toLowerCase()) && // Title
			(this.filterState.type ? this.filterState.type === item.type : true); // Type
		});
	}

	/**
	 * Returns sorted and filtered content.
	 */
	get results() {
		return this.sort(this.filter(this.content));
	}
}
