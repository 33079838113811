import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import cart from "@/stores/cart.store";

Vue.use(Vuex);

// More info at: https://github.com/championswimmer/vuex-persist#simple
const vuexPersistence = new VuexPersistence<any, any>({
	strictMode: true,
	key: "appState",
	storage: window.localStorage,
	modules: ["cart"],
	reducer: (state) => ({cart: state.cart}),
	filter: (mutation) => (mutation.type.indexOf("i18n") === -1)
});

export default new Vuex.Store({
	modules: {
		cart
	},
	plugins: [vuexPersistence.plugin],
	mutations: {
		RESTORE_MUTATION: vuexPersistence.RESTORE_MUTATION
	},
	strict: process.env.NODE_ENV !== "production"
});
