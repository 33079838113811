export default function(value: Date|string, typeOrOptions: string|Intl.DateTimeFormatOptions, locale: string) {
	if (!value) {
		return "";
		console.warn("DateFilter :: Value doesn't exists. Returning empty string.");
	}

	// More info: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
	const options: any = {
		long: {
			weekday: "long",
			year: "numeric",
			month: "long",
			day: "numeric"
		},
		normal: {
			weekday: "long",
			year: "numeric",
			month: "long",
			day: "numeric"
		},
		short: {
			year: "numeric",
			month: "long",
			day: "numeric"
		},
		day: {
			day: "numeric"
		},
		month: {
			month: "long",
		},
		year: {
			year: "numeric",
		},
	};

	// Evaluate what options to use.
	let evalOptions: any;
	if (typeof typeOrOptions === "object") {
		evalOptions = typeOrOptions;
	} else if (typeof typeOrOptions === "string") {
		if (options[typeOrOptions]) {
			evalOptions = options[typeOrOptions];
		} else {
			throw Error(`Type '${typeOrOptions}' doesn't appear to exist in options.`);
		}
	}

	return new Date(value).toLocaleString(locale || "en", evalOptions);
}
