import { Product } from "@/data/product";

export class ProductInCart {
	public id: number;
	public name: string;
	public description: string;
	public price: number;
	public quantity: number;

	constructor(product: Product, quantity?: number) {
		this.id = product.id;
		this.name = product.name;
		this.description = product.description;
		this.price = product.price;
		this.quantity = Number(quantity && quantity > 1 ? quantity : 1);
	}
}
