// tslint:disable:no-shadowed-variable

import { Product } from "@/data/product";
import { ProductInCart } from "@/data/product-in-cart";
import { Cart } from "@/data/cart";

// State.
const state: Cart = {
	items: [],
	totalAmount: 0
};

// Getters.
// You can think of them as computed properties for stores. Like computed properties, a getter's result is cached
// based on its dependencies, and will only re-evaluate when some of its dependencies have changed.
const getters = {
	getItems: (state: Cart): ProductInCart[] => {
		return state.items;
	},
	getProductById: (state: Cart, id: number): ProductInCart|null => {
		return state.items.find((item: ProductInCart) => {
			return item && item.id === id;
		}) || null;
	},
	getProductsByName: (state: Cart, query: string): ProductInCart[] => {
		query = query.toLocaleLowerCase();
		return state.items.filter((item) => {
			return query.indexOf(item.name.toLocaleLowerCase()) || query.indexOf(item.description.toLocaleLowerCase());
		});
	},
	getTotalAmount: (state: Cart): number => {
		return state.totalAmount;
	}
};

// Mutations.
// The only way to actually change state in a Vuex store is by committing a mutation.
const mutations = {
	addNewItem: (state: Cart, payload: ProductInCart): void => {
		state.items.push(payload);
	},
	incrementItemQuantity: (state: Cart, payload: {productId: number, quantity: number}): void => {
		const item = getters.getProductById(state, payload.productId);
		if (item) {
			item.quantity = Number(item.quantity) + Number(payload.quantity);
		}
	},
	decrementItemQuantity: (state: Cart, payload: {productId: number, quantity: number}): void => {
		const item = getters.getProductById(state, payload.productId);
		if (item) {
			item.quantity = Number(item.quantity) - Number(payload.quantity);
		}
	},
	refreshTotalAmount: (state: Cart) => {
		state.totalAmount = 0;
		if (state.items.length) {
			state.items.forEach((element) => {
				if (element) {
					state.totalAmount += (element.price * element.quantity);
				}
			});
		}
	}
};

// Actions.
// Actions are similar to mutations, the differences being that:
// · Instead of mutating the state, actions commit mutations.
// · Actions can contain arbitrary asynchronous operations.
const actions = {
	incrementAfterSomeTime: ({commit}: any): void => {
		setTimeout(() => {
			commit("addItem");
		}, 1000);
	},
	addItem: ({commit, state}: any, payload: {product: Product, quantity: number}): void => {
		const item = new ProductInCart(payload.product, payload.quantity);

		// Evaluate if should increment item quantity or add.
		if (getters.getProductById(state, item.id)) {
			commit("incrementItemQuantity", {productId: item.id, quantity: payload.quantity});
		} else {
			commit("addNewItem", item);
		}

		// Refresh total amount.
		commit("refreshTotalAmount");
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
