import { Vue, Component } from "vue-property-decorator";

@Component({
	name: "HeroBlock"
})

export default class HeroBlockComponent extends Vue {

	private slideIndex: number = 0;

	private userInteraction: boolean = false;

	private timePerSlide: number = 5000; // in milliseconds.

	private entries: string[] = [
		"/images/image-25.jpg",
		"/images/image-27.jpg",
		"/images/image-28.jpg",
		"/images/image-26.jpg"
	];

	private content: any = {
		title: "Jafal is a project to explore the fusion of cultures through a blending of diverse musical influences.",
		description: "In our world a tribalist mentality is one of the major origins of conflict, through a synthesis of what is special and beautiful in different cultures, we can achieve an anti-tribalist expression that transcends ethnic barriers."
	};

	private mounted() {
		this.playSlideshow();
	}

	private slideshowNavigation(action: "previous"|"next") {
		if (action === "previous") {
			// Edges navigation
			if (this.slideIndex === 0) {
				this.slideIndex = this.entries.length - 1;
			// Middle navigation
			} else {
				this.slideIndex--;
			}
		} else if (action === "next") {
			// Edges navigation
			if (this.slideIndex === this.entries.length - 1) {
				this.slideIndex = 0;
			// Middle navigation
			} else {
				this.slideIndex++;
			}
		}
	}

	private playSlideshow() {
		const timer = setInterval(() => {
			if (this.userInteraction) {
				clearInterval(timer);
			} else {
				this.slideshowNavigation("next");
			}
		}, this.timePerSlide);
	}
}
