import Vue from "vue";
import Router from "vue-router";

import HomePage from "@/views/home/home.page.vue";
import EntryPage from "@/views/entry/entry.page.vue";
import AboutPage from "@/views/about/about.page.vue";

Vue.use(Router);

const router = new Router({
	mode: "history",
	routes: [
		// Home
		{
			path: "/",
			name: "HomeView",
			component: HomePage
		},
		{
			path: "/home",
			redirect: "/",
		},
		// Lists
		{
			path: "/search",
			component: HomePage,
			name: "SearchView",
			props: (route) => ({ query: route.query.types }),
			meta: {
				scrollTo: "#gallery"
			}
		},
		// Entries
		{
			path: "/music/:slug",
			name: "MusicEntryPage",
			components: {
				default: HomePage,
				modal: EntryPage
			}
		},
		{
			path: "/videos/:slug",
			name: "VideoEntryPage",
			components: {
				default: HomePage,
				modal: EntryPage
			}
		},
		{
			path: "/photos/:slug",
			name: "PhotoEntryPage",
			components: {
				default: HomePage,
				modal: EntryPage
			}
		},
		// About
		{
			path: "/about",
			name: "AboutPage",
			component: AboutPage
		},
	]
});

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.scrollTo) && document.querySelector(to.meta.scrollTo)) {
		document.querySelector(to.meta.scrollTo)!.scrollIntoView({
			behavior: "smooth"
		});
	}
	next();
});

export default router;
