// import Store from "@/store";

// tslint:disable-next-line:max-line-length
export default function(value: number|string, currencyOrOptions: string | Intl.NumberFormatOptions, locale: string): string {
	if (!value) {
		return "";
		console.warn("DateFilter :: Value doesn't exists. Returning empty string.");
	}

	// Merge options object.
	// More info: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
	let options;
	const defaultOptions = {
		currency: "EUR",
		style: "currency",
		currencyDisplay: "symbol"
	};

	if (typeof currencyOrOptions === "string") {
		options = defaultOptions;
		options.currency = currencyOrOptions;
	} else if (typeof currencyOrOptions === "object") {
		options = {
			...defaultOptions,
			...currencyOrOptions
		};
	} else {
		console.warn("DateFilter :: Argument must be a string or an object. Using default options.'");
		options = defaultOptions;
	}

	// console.log(Store.getters("i18n/getCurrentLang"));

	// tslint:disable-next-line:no-construct
	return new Number(value).toLocaleString(locale || "en", options);
}
