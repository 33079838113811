

import { Vue, Component, Prop, Provide } from "vue-property-decorator";

@Component({
	name: "GridBlock"
})

export default class GridBlockComponent extends Vue {

}

