import { Vue, Component, Prop, Provide } from "vue-property-decorator";

@Component({
	name: "EntryPage"
})

export default class EntryPageComponent extends Vue {

	@Provide() private isReady: boolean = false;
	@Provide() private projectType!: string|undefined;
	@Provide() private projectSlug!: string|undefined;
	@Provide() private content!: any;

	private created() {
		this.projectType = this.$route.name;
		this.projectSlug = this.$route.params.slug;

		this.getContent();
	}

	private getContent() {
		const content = require("@/data/list.json").data;
		this.content = content.find((item: any) => item.slug === this.projectSlug);

		this.isReady = true;
	}

}
