import { render, staticRenderFns } from "./grid.block.pug?vue&type=template&id=35b65de6&lang=pug&"
import script from "./grid.block.vue?vue&type=script&lang=ts&"
export * from "./grid.block.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "grid.block.vue"
export default component.exports