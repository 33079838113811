import { render, staticRenderFns } from "./entry.page.pug?vue&type=template&id=3da1af0d&lang=pug&"
import script from "./entry.page.ts?vue&type=script&lang=ts&"
export * from "./entry.page.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "entry.page.vue"
export default component.exports