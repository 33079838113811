import { render, staticRenderFns } from "./about.page.vue?vue&type=template&id=51adc31d&lang=pug&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "about.page.vue"
export default component.exports